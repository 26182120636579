import React, { FC } from 'react';
import ForgotPasswordForm from '../components/forgotPassword/forgotPasswordForm';

const ForgotPasswordPage: FC = () => {
  return (
    <div>
      <ForgotPasswordForm />
    </div>
  );
};

export default ForgotPasswordPage;
