import React, { FC, useEffect } from 'react';
import { Formik, FormikProps, Form, Field, FieldProps } from 'formik';
import getTranslations from '../../utils/getTranslations';
import * as Yup from 'yup';
import { changePassword } from '../../utils/changePassword';

interface FormValues {
  password: string;
  confirmPassword: string;
}

const ForgotPasswordForm: FC = () => {
  const {
    title,
    password,
    confirmPassword,
    submitted,
    validationPasswordRequired,
    validationPasswordConfirmRequired,
    validationPasswordMatch,
    validationTooShort,
    confirm,
    noToken
  } = getTranslations().forgotPassword!;
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);
  const [token, setToken] = React.useState<string | null>('no token');
  const [serverError, setServerError] = React.useState<string>('');

  useEffect(() => {
    setToken(new URLSearchParams(window.location.search).get('token'));
  }, []);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(validationPasswordRequired!)
      .min(4, validationTooShort!),
    confirmPassword: Yup.string()
      .required(validationPasswordConfirmRequired!)
      .oneOf([Yup.ref('password', undefined)], validationPasswordMatch!)
  });

  const innerForm = (
    <Formik
      validationSchema={validationSchema}
      initialValues={{ password: '', confirmPassword: '' }}
      onSubmit={async (values: FormValues) => {
        setIsSubmitted(true);
        const error = await changePassword(values.password, token!);
        if (error) {
          setServerError(error);
        }
      }}
      render={(formikBag: FormikProps<FormValues>) => (
        <Form>
          <Field
            name='password'
            render={({ field }: FieldProps<FormValues>) => (
              <div className='field'>
                <label className='label custom-label'>{password}</label>
                <input type='password' {...field} className='input' />
                <p className='help is-danger'>
                  {(formikBag.touched.password && formikBag.errors.password) ||
                    ' '}
                </p>
              </div>
            )}
          />
          <Field
            name='confirmPassword'
            render={({ field }: FieldProps<FormValues>) => (
              <div className='field'>
                <label className='label custom-label'>{confirmPassword}</label>
                <input type='password' {...field} className='input' />
                <p className='help is-danger'>
                  {(formikBag.touched.confirmPassword &&
                    formikBag.errors.confirmPassword) ||
                    ' '}
                </p>
              </div>
            )}
          />
          <div>
            <button
              id='submit-form-button'
              disabled={!formikBag.isValid}
              className='button is-rounded is-white'
              type='submit'
            >
              {confirm}
            </button>
          </div>
        </Form>
      )}
    />
  );

  return (
    <div id='contact'>
      <div className='column is-6 is-offset-3' style={{}}>
        {token && !isSubmitted && (
          <div className='has-text-centered' id='text'>
            <h2>{title}</h2>
          </div>
        )}
        {!isSubmitted && token && (
          <div style={{ width: '100%' }}>{innerForm}</div>
        )}
        {isSubmitted && !serverError && (
          <div style={{ minHeight: '398px' }} className='has-text-centered'>
            <span>{submitted}</span>
          </div>
        )}
        {!token && (
          <div style={{ minHeight: '398px' }} className='has-text-centered'>
            <span>{noToken}</span>
          </div>
        )}
        {serverError && (
          <div style={{ minHeight: '398px' }} className='has-text-centered'>
            <span>{serverError}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
