import axios from 'axios';
import getTranslations from './getTranslations';

const checkChangePwToken = async (token: string): Promise<string> => {
  if (process.env.NODE_ENV === 'development') {
    // tslint:disable-next-line: no-console
    console.log('in dev mode, pretending to validate token');
  } else {
    if (typeof window !== undefined) {
      const res = await axios.get<{ expiration_date: Date }>(
        '/api/token/' + token
      );
      const expirationDate = res.data.expiration_date;
      const now = new Date();
      if (now > expirationDate) {
        return getTranslations().forgotPassword!.expiredToken!;
      }
    }
  }
  return '';
};

export const changePassword = async (
  password: string,
  token: string
): Promise<string> => {
  try {
    const tokenError = await checkChangePwToken(token);
    if (tokenError) {
      return tokenError;
    }
    await axios.post('/api/user/set_forgotten_password', { token, password });
  } catch (error) {
    return error.message;
  }
  return '';
};
